.input-border {
  border-radius: 4px !important;
  border-color: hsl(0,0%,80%);
  height: 38px;
}

.search-input-field, .date-picker-input {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: hsl(0,0%,50%);
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: hsl(0,0%,50%);
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: hsl(0,0%,50%);
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: hsl(0,0%,50%);
  }
}

.date-picker-clear-icon {
  position: absolute;
  top: 30%;
  right: 5px;
  cursor: pointer;
  color: hsl(0,0%,50%);
}