.check-icon {
  font-size: 18px;
  cursor: pointer;

  &.lg {
    font-size: 20px;
  }
  
  &:hover {
    color: #556ee6;
  }

  &.active {
    color: #556ee6;
  }
}